body {
  margin: 0;
  font-family: "Poppins,sans-serif";
}

select:required:invalid {
  color: gray;
}
/* option[value=""][disabled] {
  color: gray;
} */
option {
  color: black;
}
